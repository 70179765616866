import React, {useEffect,useState} from 'react';
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';

// Styles
import './styles.scss';

// Components
import Navigation from '../../components/Navigation';
import Content from '../../components/Content';
import Loader from '../../components/Loader';

// Utils
import checkPermission from '../../utils/checkPermission';

function HomeView() {

	const [showLoader, setShowLoader] = useState(true);
	const [homeItems, setHomeItems] = useState([]);
	const navigate = useHistory();

	useEffect(() => {
		// Get user ID
		const data = localStorage.getItem("data");
		const parsedData = JSON.parse(data);
		const userId = parsedData.id;

		// Get user permissions
		axios.get(`${process.env.REACT_APP_API_URL}/api/permissions/${userId}`)
			.then((res) => {
				setShowLoader(false);
				let tempHomeItems = [];

				res.data.data.forEach((item) => {

					if (item.permission_key === "PROZ-START-RECHNUNGEN" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "Rechnungen",
							icon: "request_quote",
							link: "/default-processes/rechnungen"
						})
					}

					if (item.permission_key === "PROZ-START-PRODUKTE" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "Produkte",
							icon: "archive",
							link: "/default-processes/produkte"
						})
					}

					if (item.permission_key === "PROZ-START-KUNDEN" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "Kunden",
							icon: "people",
							link: "/default-processes/kunden"
						})
					}

					if (item.permission_key === "PROZ-START-SERVICE-CENTER" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "Service Center",
							icon: "support_agent",
							link: "/default-processes/service-center"
						})
					}

					//KUNDENSTAMM
					if (item.permission_key === "PROZ-START-KUNDENSTAMM" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "Kundenstamm",
							icon: "donut_large",
							link: "/"
						})
					}

					if (item.permission_key === "PROZ-START-RABATTE" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "Rabatte",
							icon: "sell",
							link: "/"
						})
					}

					if (item.permission_key === "PROZ-START-MANUELLE-RECHNUNG" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "Man. Rechnung",
							icon: "receipt_long",
							link: "/default-processes/manuelle-rechnung"
						})
					}

					if (item.permission_key === "PROZ-START-VERWALTUNG" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "Verwaltung",
							icon: "tune",
							link: "/default-processes/verwaltung"
						})
					}

					if (item.permission_key === "PROZ-START-ARCHIV" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "Archiv",
							icon: "folder_copy",
							link: "/default-processes/archiv"
						})
					}

					if (item.permission_key === "PROZ-START-PERSONAL" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "Personal",
							icon: "badge",
							link: "/"
						})
					}

					if (item.permission_key === "PROZ-START-LAGER" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "Lager",
							icon: "inventory_2",
							link: "/"
						})
					}

					if (item.permission_key === "PROZ-START-BUCHUNGEN" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "Buchungen",
							icon: "compare_arrows",
							link: "/default-processes/buchungen"
						})
					}

					if (item.permission_key === "PROZ-START-BANK" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "Bank",
							icon: "account_balance",
							link: "/"
						})
					}

					if (item.permission_key === "PROZ-START-INTERNE-KONTEN" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "Interne Konten",
							icon: "account_balance_wallet",
							link: "/default-processes/interne-konten"
						})
					}

					if (item.permission_key === "PROZ-START-ENTWICKLER" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "Entwickler",
							icon: "api",
							link: "/default-processes/developer"
						})
					}

					if (item.permission_key === "PROZ-START-CUSTOM-KFZ-KALKULATOR" && item.permission_value === 1) {
						tempHomeItems.push({
							title: "KFZ-Bedruck-Kalkulator",
							icon: "directions_car",
							link: "/custom-processes/kfz-kalkulator"
						})
					}

				})

				setHomeItems(tempHomeItems);

			})
			.catch((err) => {
				navigate.push("/status/error");
			})

	}, [])

	return (
		<>
			{showLoader && (
				<Loader />
			)}
			<Content>
				<div className="Home-Grid">


					{homeItems.map((item, index) => (
						<Link to={item.link} key={index} className="Home-Grid-Item">
							<div className="Home-Grid-Item-Container">
								<span className="material-symbols-rounded">
									{item.icon}
								</span>
							</div>
							<span className="Home-Grid-Item-Title">{item.title}</span>
						</Link>
					))}

				</div>
			</Content>
		</>
	);
}

export default HomeView;